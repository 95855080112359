<template>
	<form @keydown.enter="changePassword()">
		<h1 v-if="CONFIG.w.wChangepwd.showHeading">{{ $t('Zmeniť heslo') }}</h1>

		<w-user-password-change :userInfo="userInfo" showRepeat="true" />

		<b-button variant="primary" @click.prevent="changePassword()" :disabled="$wToast.isLoading()">{{ $t('Uložiť nové heslo') }}</b-button>
	</form>
</template>

<script>
export default {
	components: {
		'w-user-password-change': () => import('./w-user-password-change')
	},

	data() {
		return {
			userInfo: {
				password: '',
				password_confirmation: ''
			},
		}
	},
	
	methods: {
		async changePassword() {
			this.$wToast.clear_loading()

			if (!this.userInfo.password.length) {
				this.$wToast.error(this.$t('Heslo nie je vyplnené'))
				return
			}

			try {
				await this.$store.dispatch('wAuth/updateUserInfo', this.userInfo)
				this.$wToast.success(this.$t('Vaše heslo bolo úspešne zmenené'))
				this.userInfo.password = ''
				this.userInfo.password_confirmation = ''
			} catch (error) {
				this.$wToast.error(error)
			}
		}
	}
}
</script>